$body-bg: #14233c;
$body-color: #fff;

$blue: #2991cc;
$indigo: #6f48bc;
$purple: #53368d;
$pink: #ff4d56;
$red: #e74c3c;
$orange: #db6f26;
$yellow: #f1c430;
$green: #61cd73;
$teal: #55bd9c;
$cyan: #2991cc;
$white: #ffffff;
$gray-600: #707b8c;
$gray-800: #34495e;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);

.refresh-button {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -10px;
  margin-left: 5px;
  background-color: $white !important;
  border-color: $white !important;
  color: $gray-600 !important;
}
